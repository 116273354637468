import React from "react";
import {NosValeursPage} from 'amg-it-theme'; 

let nosValeursPageData ={
  header: {
    background: "societe",
    title: "société",
    path: "ACCUEIL / SOCIÉTÉ / NOS VALEURS ?"
  },
  content:{
    category: "nos valeurs",
    title: "Une politique RH inédite",
    subtitle: "Le cœur de métier d’AMG-IT, c’est la gestion de l’humain. L’ESN emploie une cinquantaine de collaborateurs salariés, intérimaires et indépendants."
  },
  band_text: "L’humain au cœur des projets",
  valeurs:{
      presentation_text: "Structure de taille humaine, AMG-IT joue la carte du management participatif et collaboratif. Les collaborateurs bénéficient d’un encadrement de proximité - responsables de compte, chefs de projet, assistante RH… - sans les lourdeurs hiérarchiques d’une grande organisation. Chez AMG-IT, les employés sont des personnes et non des ressources. Autonomes, ils s’épanouissent en intervenant sur une grande variété de missions.",
      blocs: [
        {
          icon: "teacher",  
          text: "Le recrutement des techniciens de support se fait au niveau BTS ou DUT, avec ou sans expérience. AMG-IT donne aussi leur chance aux autodidactes fortement motivés. Elle offre aussi des progressions de carrière à la carte. Un technicien peut par exemple suivre la filière de l’expertise technique et devenir administrateur ou bien suivre la filière du management et passer chef d’équipe."
        },
        {
          icon: "money",  
          text: "Indexées sur la grille de salaires du Syntec, les rémunérations augmentent régulièrement tous les ans. AMG-IT récompense ses collaborateurs de différentes primes : primes d’assiduité, primes de mission, voire primes exceptionnelles en cas de dépassement des objectifs."
        }
        ]
    }
}

const LocalPage = () => (
  <NosValeursPage {...nosValeursPageData}/>
);

export default LocalPage;